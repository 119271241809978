import { useContext, useEffect, useState } from 'react'
import NextLink from 'next/link'
import { Image } from 'components/Image'
import { Button, Typography } from '@achieve/ascend'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import useIsSsr from 'hooks/useIsSsr'
import Cookies from 'js-cookie'
import styles from './ConsentBanner.module.scss'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { useIsProduction } from 'hooks/useIsProduction'
import classNames from 'classnames'
const CONSENT_BANNER_COOKIE = 'achieve_tracking_consent_banner'

const bannerCopy = [
  'We use and allow cookies and similar tools on our websites to enable essential functionalities and to better understand how visitors use our site (including use of session replay technology), as well as for personalization, social media features, and advertising. Read our',
  'privacy policy',
  'to learn more. By closing this box or by your continued use of our sites, you accept such use.',
]

const ConsentBanner = function ({ contentfulId, contentfulVersion }) {
  const {
    state: { isPageLoaded },
  } = useContext(AnalyticsContext)
  const isProd = useIsProduction()
  const isSsr = useIsSsr()
  const { fireAndForget } = useTriggerEvents()
  const [acknowledgedState, setAcknowledgedState] = useState(false)
  const consentBannerCookie = Cookies.get(CONSENT_BANNER_COOKIE)
  // We know that the user has consented to the banner if they have the cookie because the cookie is only set when the user clicks "Ok" or clicks "x"
  const isConsentBannerAcknowledged = !!consentBannerCookie

  const onClick = () => {
    let cookieDomain = '.ffngcp.com'

    if (isProd) {
      cookieDomain = '.achieve.com'
    }

    const visit_id = Cookies.get('eh_visit_id')

    Cookies.set(
      'achieve_tracking_consent_banner',
      JSON.stringify({
        visitId: visit_id,
        contentfulId: contentfulId,
        contentfulVersion: contentfulVersion,
      }),
      {
        expires: 3650,
        domain: cookieDomain,
      }
    )

    setAcknowledgedState(true)
  }

  useEffect(() => {
    !isConsentBannerAcknowledged &&
      // For the tealium banner version, consider:
      // 1. Fire off an identical app_event there
      // 2. Tell BE they can ignore the page_event for `_views/policy` which they were looking for prior.
      fireAndForget({
        event_type: 'app_event',
        event_action: 'app_event',
        nav_link_section: 'TCB banner',
        track_event: '_views/policy',
        disclosure_copy: `${bannerCopy[0]} ${bannerCopy[1]} ${bannerCopy[2]}`,
        ...handleTrackAndReactEvent(event, {}),
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageLoaded, isConsentBannerAcknowledged])

  return (
    <>
      {!isSsr && !isConsentBannerAcknowledged && !acknowledgedState && (
        <div className={classNames(styles['tcb-banner'], 'z-50')} data-testid="tcb-banner">
          <div className={styles['tcb-x']} data-testid="tcb-close-container">
            <TrackingWrapper
              track={{
                list_name: 'Close consent click event',
                nav_link_section: 'TCB banner',
                event_action: 'ui_click',
              }}
              onClick={onClick}
            >
              <Image
                src="/close.svg"
                alt="Close button"
                width={18}
                height={18}
                priority={false}
                aria-hidden={true}
                data-testid="tcb-x-icon"
              />
            </TrackingWrapper>
          </div>
          <Typography
            component="div"
            data-testid="tcb-text-content"
            className={classNames(styles['tcb-content'], 'bodyXs lg:bodyMd')}
          >
            <div className={styles['tcb-btn']}>
              <TrackingWrapper
                track={{
                  list_name: 'Tracking consent click event',
                  nav_link_section: 'TCB banner',
                  click_text: 'Ok',
                  event_action: 'button_click',
                }}
                onClick={onClick}
              >
                <Button size="small" variant="contained" data-testid="tcb-ok-button">
                  <Typography
                    component="div"
                    className={classNames(styles['button-padding'], 'bodyMd lg:bodyLg')}
                    data-testid="tcb-text-content"
                  >
                    Ok
                  </Typography>
                </Button>
              </TrackingWrapper>
              &nbsp;
            </div>
            <div className="mb-[6px] md:mb-0">
              {bannerCopy[0]}
              <TrackingWrapper
                track={{
                  list_name: 'Policies link click event',
                  nav_link_section: 'TCB banner',
                  click_text: 'Privacy policy',
                  event_action: 'link_click',
                }}
              >
                <NextLink href="/policies#Privacy-Policy" target="blank">
                  {bannerCopy[1]}
                </NextLink>
              </TrackingWrapper>
              &nbsp;
              {bannerCopy[2]}
            </div>
          </Typography>
        </div>
      )}
    </>
  )
}

export { ConsentBanner }
